.react-json-view {
    font-family: Montserrat!important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    word-break: break-all;
}

.object-key {
    color: var(--color-green)!important;
}

.variable-value > div {
    color: var(--color-orange)!important;
}

.icon-container + span + span {
    font-weight: 500!important;
    color: var(--color-dark-grey5)!important;
}

.icon-container svg {
    fill: var(--color-grey50);
}

.brace-row > span {
    font-weight: 500!important;
    color: var(--color-dark-grey5)!important;
}
