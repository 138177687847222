:root {
    --color-blue:#176ED6;
    --color-dark-grey5:#142c4a;
    --color-light-grey95:#ebedf0;
    --color-grey50:#83909f;
    --color-grey75:#c1c7cf;
    --color-white:#fff;
    --font-bold: 600;
    --font-family: "Montserrat";
    --sidebar-width: 460px;
    --sidebar-zIndex: 99;
}

#editorjs {
    --toolbar-opened-z-index: 99;
    padding-left: 70px;
    padding-right: 70px;
}

.codex-editor .ce-block__content, .codex-editor .ce-toolbar__content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
}

.codex-editor .ce-toolbar__content .ce-popover-item__icon {
    min-width: 26px;
}

.codex-editor .ce-toolbar--opened {
    z-index: var(--toolbar-opened-z-index);
}

.codex-editor .ce-inline-toolbar__actions {
    position: relative;
}

.codex-editor h1.ce-header, .codex-editor h2.ce-header,
.codex-editor h3.ce-header, .codex-editor h4.ce-header {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: var(--font-bold);
}

.codex-editor h1.ce-header {
    font-size: 36px;
}

.codex-editor h2.ce-header {
    font-size: 24px;
}

.codex-editor h3.ce-header {
    font-size: 20px;
}

.codex-editor .image-tool__image {
    display: flex;
    justify-content: center;
}

.codex-editor .tc-add-column,
.codex-editor .tc-add-row {
    visibility: hidden;
}

.codex-editor .tc-wrap:hover .tc-add-column,
.codex-editor .tc-wrap:hover .tc-add-row {
    visibility: visible;
}

.codex-editor .tc-row {
    border-left: 1px solid var(--color-border);
    break-inside: avoid;
    page-break-inside: avoid;
}

.codex-editor .tc-table--heading .tc-row:first-child:after,
.codex-editor .tc-row:after {
    border-bottom: none;
}

.codex-editor .tc-wrap:hover .tc-table--heading .tc-row:first-child:after {
    border-bottom: 2px solid var(--color-border);;
}

.codex-editor .tc-wrap:hover .tc-row:after {
    border-bottom: 1px solid var(--color-border);;
}

.codex-editor .cdx-list {
    padding-left: 20px;
}

.codex-editor .cdx-list.cdx-list--unordered .cdx-list__item {
    list-style: disc;
}

.pflb-sidebar-settings,
.codex-editor .pflb-sidebar-settings {
    background: var(--color-white);
    bottom: 0;
    box-shadow: 0px 0px 8px rgba(0, 64, 140, 0.15);
    display: none;
    flex-direction: column;
    height: calc(100% - 60px);
    min-width: var(--sidebar-width);
    overflow-y: auto;
    padding: 18px;
    position: fixed;
    width: var(--sidebar-width);
    z-index: var(--sidebar-zIndex);
}

.pflb-sidebar-settings.show,
.codex-editor .pflb-sidebar-settings.show {
    display: flex;
    right: 0;
    visibility: visible;
}

.codex-editor .cdx-nested-list {
    padding: .4em 0;
}

@media print {
    @page {
        margin: 1cm;
        size: a4;
    }

    body #root {
        height: auto;
    }

    .ce-inline-toolbar, .ce-toolbar, .ce-toolbar__actions, .tc-add-row, .tc-add-column {
        display: none;
        visibility: hidden;
    }

    .codex-editor .image-tool .image-tool__caption {
        border: none;
        box-shadow: none;
    }

    .tc-table {
        height: fit-content;
        max-height: fit-content;
        min-height: fit-content;
    }
}