.pflb-anchor {
    border-bottom: 1px dashed var(--color-blue);
    position: relative;
}

.pflb-anchor--selected {
    background-color: #d4ecff;
    border-bottom: 0;
}

.pflb-anchor-button {
    align-items: center;
    background: none;
    border: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    outline: none;
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.pflb-anchor-inline-tool .ce-inline-tool-input {
    padding-right: 28px;
}

.ce-inline-toolbar__actions .button-copy {
    bottom: 8px;
    cursor: pointer;
    height: 18px;
    right: 6px;
    width: 18px;
}

.ce-inline-toolbar__actions .button-copy--showed {
    opacity: 1;
    visibility: visible;
}

.pflb-anchor-inline-tool .button-tooltip {
    cursor: help;
    height: 20px;
    right: 6px;
    top: 8px;
    width: 20px;
}

.pflb-anchor-inline-tool .button-tooltip:hover .pflb-anchor-link-tooltip {
    opacity: 1;
    visibility: visible;
}

.pflb-anchor-link-tooltip {
    background: var(--white);
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(23, 128, 224, 0.25);
    color: #b2b2b2;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 5px;
    max-width: 200px;
    min-width: 200px;
    opacity: 0;
    padding: 5px 10px;
    position: absolute;
    text-align: center;
    top: -70px;
    visibility: hidden;
}

.ce-inline-tool-input--showed + .pflb-anchor-inline-tool .button-tooltip {
    opacity: 1;
    visibility: visible;
}

.ce-inline-tool[data-tool="anchor"] svg {
    height: 16px;
    width: 16px;
}

.ce-inline-tool[data-tool="anchor"] svg path {
    stroke: unset
}

.ce-inline-tool--strike-through-anchor line {
    stroke: currentColor;
}

.ce-inline-tool--strike-through-anchor path {
    stroke: unset;
    fill: currentColor;
}
