.pflb-table-of-contents {
    --level-margin-left: 24px;
}

.pflb-toc-item {
    outline: none;
}

.pflb-toc-margin-level2 {
    margin-left: var(--level-margin-left);
}

.pflb-toc-margin-level3 {
    margin-left: calc(2 * var(--level-margin-left));
}

.pflb-toc-margin-level4 {
    margin-left: calc(3 * var(--level-margin-left));
}
