.pflb-chart {
    break-inside: avoid;
    margin-top: 8px;
    padding-bottom: 8px;
    page-break-inside: avoid;
    position: relative;
}

.pflb-chart .chart-header {
    height: fit-content;
    min-height: fit-content;
}

.pflb-chart path {
    stroke: inherit;
}

.pflb-chart .chart-preview-wrapper {
    flex-direction: column;
}

.pflb-chart .recharts-legend-wrapper .recharts-legend-icon {
    stroke: revert-layer;
}

.pflb-chart .recharts-legend-wrapper .recharts-legend-item {
    margin-top: 10px;
}

.pflb-chart-OLD {
    align-items: center;
    background: var(--color-white);
    border-radius: 12px;
    box-shadow: 0px 0px 8px rgba(0, 64, 140, 0.15);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 24px;
    min-height: 400px;
    padding: 12px;
    position: relative;
}

.pflb-chart:hover .settings-button {
    display: flex;
}

.chart-params-wrapper {
    padding: 12px;
}

.chart-params-wrapper h3 {
    margin-bottom: 6px;
}

.chart-params-wrapper .button {
    font-family: Montserrat;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    pointer-events: auto;
    opacity: 1;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--color-blue);
    color: var(--color--white);
    border: 2px solid transparent;
    border-radius: 30px;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 100%;
    margin-left: 6px;
    outline: none;
    padding: 8px 26px;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chart-params-wrapper input {
    border: 1px solid var(--color-grey75);
    border-radius: 12px;
    pointer-events: auto;
    opacity: 1;
    font-family: Montserrat;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    background: var(--color-white);
    border-color: var(--color-grey75);
    box-sizing: border-box;
    box-shadow: inset 0 0 0 20px var(--color-white) !important;
    color: var(--color-grey50);
    height: 100%;
    outline: none;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    position: relative;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    width: 100%;
}

.chart-params {
    display: flex;
}

.close-button {
    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 2px;
}

@media print {
    .pflb-chart .chart-header .settings-button {
        display: none;
    }

    .pflb-chart .chart-preview-wrapper {
        padding: 12px 0 0;
    }

    .pflb-chart .chart-preview-wrapper .editable-title,
    .pflb-chart .chart-preview-wrapper:hover .editable-title {
        border: none;
    }

    .pflb-chart .editable-title {
        border: none;
    }

    .pflb-chart .recharts-legend-wrapper {
        width: 100%!important;
    }

    .pflb-chart .recharts-responsive-container {
        min-height: fit-content!important;
    }

    .pflb-chart .recharts-responsive-container,
    .pflb-chart .recharts-wrapper {
        height: fit-content!important;
    }

    .pflb-chart .recharts-wrapper .recharts-surface {
        height: fit-content;
    }
}