.p-tree-container {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: auto;
}

.p-treenode-children {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.p-treenode-selectable {
    cursor: pointer;
    user-select: none;
}

.p-tree-toggler {
    cursor: pointer;
    user-select: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
}

.p-treenode-leaf > .p-treenode-content > svg {
    visibility: hidden;
}

.p-treenode-content {
    display: flex;
    align-items: center;
}

.p-tree-filter {
    width: 100%;
}

.p-tree-filter-container {
    position: relative;
    display: block;
    width: 100%;
}

.p-tree-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
}

.p-tree-loading {
    position: relative;
    min-height: 4rem;
}

.p-tree .p-tree-loading-overlay {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-tree-container {
    padding: 0 8px;
}

#root .p-tree .p-tree-container .p-treenode {
    /* background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 8px rgba(0, 64, 140, 0.15);
    padding: 0 12px 12px; */
    position: relative;
}

/* #root .p-tree .p-tree-container .p-treenode-leaf {
    padding: 0 12px 12px;
} */

#root .p-tree .p-treenode-children {
    padding: 0 0 0 40px;
}
#root .p-tree .p-treenode-droppoint {
    height: 16px;
}

#root .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    height: 80px;
    position: relative;
    border: none;
}

#root .p-tree .p-treenode-droppoint.p-treenode-droppoint-active::before {
    border: 1px dashed #ebedf0;
    border-radius: 12px;
    content: "";
    height: 46px;
    position: absolute;
    top: 16px;
    width: 100%;
}

#root .p-tree .p-tree-container .p-treenode.p-treenode-dragover > .p-treenode-content.p-treenode-dragover > div {
    background: #ebedf0;
    border: 1px solid #dadee2;
    border-radius: 12px;
}

#root .p-tree .p-tree-container .p-treenode-with-children.p-treenode-dragover > .p-treenode-content.p-treenode-dragover > div {
    background: #fff;
}

/* .p-treenode-content {
    padding-top: 12px
}

#root .p-tree .p-tree-container .p-treenode-leaf .p-treenode-content {
    padding-top: 12px;
} */
