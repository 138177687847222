@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Light.woff') format('woff'),
    url('fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Medium.woff') format('woff'),
    url('fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Black.woff') format('woff'),
    url('fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-ExtraBold.woff') format('woff'),
    url('fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Regular.woff') format('woff'),
    url('fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-SemiBold.woff') format('woff'),
    url('fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Thin.woff') format('woff'),
    url('fonts/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Bold.woff') format('woff'),
    url('fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-ExtraLight.woff') format('woff'),
    url('fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
	overflow: hidden;
}

h1, h2, h3, h4, h5 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
}

a {
    text-decoration: none;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul > li {
    list-style-type: none;
}

:root {
    --color-dark-grey5: #142c4a;
    --color-green: #17D663;
    --color-orange: #f0ad00;
    --color-grey50: #83909f;
}

#root {
    height: 100%;
    min-height: 600px;
    --color-blue: #176ED6;
    --color-dark-grey-35: #5e6f83;
    --color-grey-50: #83909f;
    --color-grey-65: #a8b1bc;
    --color-light-grey-95: #ebedf0;
    scrollbar-color: var(--color-dark-grey-35) var(--color-grey-65) var(--color-dark-grey-35);
    scrollbar-width: thin;
}

.recharts-cartesian-axis-line {
    stroke: var(--color-light-grey-95);
    stroke-width: 4px;
}

.recharts-line-curve {
    stroke-width: 4;
}

.recharts-text {
    fill: var(--color-grey-50);
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

::-webkit-scrollbar {
    width: 8px;
    background: #5e6f83;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: #A8b1bc;
    border-radius: 4px;
}

@media print {
    html, body {
        overflow: unset;
    }

    #root header {
        display: none;
    }
}