:root .pflb-table{
    --pflb-table-toolbox-z-index: 111;
}

.pflb-table {
    position: relative;
}

.pflb-table .pflb-sidebar-settings {
    align-items: flex-end;
}

.pflb-table .settings-button path,
.pflb-table .pflb-sidebar-settings path {
    stroke: inherit;
}

.pflb-table .tc-wrap {
    margin-top: calc(var(--toolbox-icon-size) + 4px);
}

.pflb-table .tc-wrap .tc-toolbox {
    z-index: var(--pflb-table-toolbox-z-index);
}

.pflb-table:hover .editable-title {
    border: 2px solid var(--color-light-grey95);
}

.pflb-table:hover .editable-title:hover {
    border: 2px solid var(--color-dark-grey5);
}

@media print {
    .pflb-table .table-header .editable-title {
        border: none;
        margin-left: 0;
    }

    .codex-editor .pflb-table .tc-wrap {
        border: none;
        margin-top: 4px;
    }

    .codex-editor .tc-wrap .tc-add-column,
    .codex-editor .tc-wrap .tc-add-row,
    .pflb-table .settings-button,
    .pflb-table .tc-wrap .tc-toolbox,
    .tc-table:after,
    .tc-table:before {
        display: none;
    }
}