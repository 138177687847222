.tc-table.pflb-resizable-table {
    border-left: 1px solid var(--color-border);
    display: table;
    max-width: 100%;
    min-width: 100%;
    width: fit-content;
}

.tc-table.pflb-resizable-table .tc-cell {
    border-bottom: 1px solid var(--color-border);
    display: table-cell;
    max-width: fit-content;
    min-width: fit-content;
    width: fit-content;
    word-break: break-word;
}

.tc-table.pflb-resizable-table .tc-row {
    display: table-row-group;
    max-width: 100%;
    min-width: 100%;
    width: fit-content;
}

.tc-table.pflb-resizable-table .tc-toolbox.tc-toolbox--column.tc-toolbox--showed {
    opacity: 0;
}

.tc-resize-column {
    background: transparent;
    cursor: col-resize;
    height: calc(100% - var(--cell-size));
    position: absolute;
    top: 0;
    width: 5px;
}

.tc-resize-column:hover, .tc-resize-column.active {
    background: var(--color-dark-grey5);
    cursor: col-resize;
}

.tc-table.resize-col-mode {
    cursor: col-resize;
}
